import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function Loading() {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6, display: 'flex' }} component="Loading">
      <CircularProgress />
    </Box>
  );
}

export default Loading;