import React, { Suspense, lazy }  from 'react';
import { Container, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import { aiItems } from './data/data';


const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const DirectoryList = lazy(() => import('./components/DirectoryList'));
const ProductDetail = lazy(() => import('./components/ProductDetail'));


function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
      <CssBaseline />
      <Header />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Routes>
          <Route path="/" element={<DirectoryList items={aiItems} />} />
          <Route path="/aitool/:name" element={<ProductDetail items={aiItems} />} />
        </Routes>
      </Container>
      <Footer />
      </Suspense>
    </Router>
  );
}

export default App;